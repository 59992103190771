import { createElement, Fragment, Suspense } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { useCurrentUser, CurrentUser } from "../lib/user";
import auth0 from "../lib/auth0";
import { authUrl } from "../lib/url";
import version from "../lib/version";

const ZoneChart = dynamic(() => import("../components/ZoneChart"), {
  ssr: false,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = auth0.getSession(context.req, context.res);

  return {
    props: { currentUser: session?.user || null },
  };
};

export default function Index(props: {
  currentUser: CurrentUser;
}): JSX.Element {
  const currentUser = useCurrentUser({ initialValue: props.currentUser }); // TODO: Look into hydrating currentUser vs initialData

  if (currentUser.data?.currentUser) {
    return (
      <>
        <span>Hello, {currentUser.data?.currentUser.name}</span> &middot;{" "}
        <span onClick={handleDoubleClickDebugError}>v{version}</span> &middot;{" "}
        <a href={authUrl(`/logout`)}>Logout</a>
        <h1>Zones</h1>
        {currentUser.status === "loading" && <div>Loading…</div>}
        {currentUser.data?.currentUser.zones?.map((zone) => (
          <div key={zone.id}>
            <h2
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              contentEditable="plaintext-only"
              suppressContentEditableWarning
            >
              {zone.origins.join(", ")}
            </h2>
            <div style={{ height: 300 }}>
              <Suspense fallback="Loading…">
                <ZoneChart zoneId={zone.id} height={300} interval="daily" />
              </Suspense>
            </div>
            {zone.placements.map((placement) => (
              <Fragment key={placement.id}>
                <div>
                  <h3
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    contentEditable="plaintext-only"
                    suppressContentEditableWarning
                  >
                    {placement.name}
                  </h3>
                  <details>
                    <summary>
                      Assets(
                      {
                        Object.keys(JSON.parse(placement.variables)).length
                      }):{" "}
                      {Object.keys(JSON.parse(placement.variables)).join(", ")}
                    </summary>
                    {rebuildJSX(JSON.parse(placement.template))}
                    <label>
                      Location:
                      <input
                        value={placement.location}
                        style={{ width: "100%" }}
                      />
                    </label>
                    <label>
                      Path:
                      <input
                        value={placement.path}
                        style={{ width: "100%" }}
                        readOnly
                      />
                    </label>
                    <label>
                      Context:
                      <textarea style={{ width: "100%", height: "100px" }}>
                        {JSON.stringify(
                          JSON.parse(placement.variables),
                          undefined,
                          2
                        )}
                      </textarea>
                    </label>
                  </details>
                </div>
                <hr />
              </Fragment>
            ))}
          </div>
        ))}
      </>
    );
  }

  return <a href={authUrl(`/login`)}>Login</a>;
}

function rebuildJSX(serializedNode, index = 0) {
  if (serializedNode.type === "element") {
    const elementProps = {
      "data-snapshot-id": serializedNode.id,
      key: serializedNode.id || index,
      style: {},
    };

    Object.keys(serializedNode.attributes).forEach((attributeName) => {
      const attribute = serializedNode.attributes[attributeName];

      if (attributeName === "class") {
        elementProps["className"] = attribute;
      } else if (attributeName === "style") {
        elementProps[attributeName] = camelCaseKeys(attribute);
      } else {
        elementProps[attributeName] = attribute;
      }
    });

    return createElement(
      serializedNode.tagName,
      elementProps,
      serializedNode.childNodes.length
        ? serializedNode.childNodes.map(rebuildJSX)
        : undefined
    );
  } else if (serializedNode.type === "text") {
    return serializedNode.textContent;
  } else if (serializedNode.type === "cdata") {
    console.warn(
      "Ignoring CDATA:",
      serializedNode.id,
      serializedNode.textContent
    );
  } else if (serializedNode.type === "comment") {
    console.warn(
      "Ignoring Comment:",
      serializedNode.id,
      serializedNode.textContent
    );
  }
}

function handleDoubleClickDebugError(event) {
  if (event.detail === 2) {
    console.log("double click");
    throw new Error("Example error");
  }
}

function camelCase(input: string): string {
  return input.replace(/-(\w)/g, (_, letter) => letter.toUpperCase());
}

function camelCaseKeys(obj: { [key: string]: any }): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  for (const key of Object.keys(obj)) {
    result[camelCase(key)] = obj[key];
  }

  return result;
}
